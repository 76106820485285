import {useEffect , useRef}from 'react';
import "./styles.scss";
import { Rating } from '@mui/material';
import { Star } from '@mui/icons-material';
import { SkeletonLine } from 'Components/Skeletion';
import { useWindowSize } from 'Utilities';
import { useSearchParams } from "react-router-dom";


const ProductTitle = ({ loading, minPrice, data, setOpenReview,openReview, setOpenAllReviews, setAccrodionView , reviewData }) => {
  const [width] = useWindowSize();
  const piecePrice = data?.additional_info?.total_price;
  const pieceQty = data?.additional_info?.qty ? data.additional_info.qty : "";
  const headerHeight = 82;
  const reviewElem = document.querySelector('.reviews__block');
  const reviewElemMobile = document.querySelector('.mobile__pdp_accordion__Reviews');
  const reviewSliderRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const reviewHandle = () => {
    if(width >= 768){
      window.scroll({ top: (reviewElem.offsetTop - headerHeight), left: 0, behavior: 'smooth' });
    }else{
      window.scroll({ top: (reviewElemMobile.offsetTop - headerHeight), left: 0, behavior: 'smooth' });
      setAccrodionView("Reviews");
    }
  };

  useEffect(() => {
    if (searchParams.get("review") === "1") {
      setOpenReview(true);
    }
  }, [searchParams, setOpenReview]);

  const handleReviewClick = () => {
    setOpenReview(true);
    setSearchParams({ review: "1" }, { replace: true }); 
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const reviewSlider = document.querySelector(".sidebar__review");
      if (reviewSlider && !reviewSlider.contains(event.target)) {
        setOpenReview(false);
        setSearchParams({}, { replace: true }); 
      }
    };
  
    if (openReview) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openReview, setSearchParams]);
  
  return loading && !data?.product_name ? (
    <div className='productTitle flex col gap-1'>
      <SkeletonLine height='36px' style={{width: "100%"}} />
      <SkeletonLine height='24px' style={{width: "60%"}} />
      <SkeletonLine height='24px' style={{width: "50%"}} />
      <SkeletonLine height='24px' style={{width: "40%"}} />
    </div>
  ) : (
    <div className='productTitle flex col gap-1'>
      <h1>{data?.product_name}</h1>
      {piecePrice ? (<p>Totaalprijs bij {pieceQty} {pieceQty?"stuks":""} vanaf {data?.stock_status && piecePrice} (incl. BTW)</p>) : <></>}
      <div className="flex row left gap-2 md-flex md-row md-middle wrap info">
  <span className='flex row gap-2 middle'>
    <i className={`stockStatus ${(data?.temporary_out_of_stock == "0" && data?.stock_status === true) || data?.temporary_out_of_stock == null  ? 'in-stock' : 'out-of-stock'}`} />
    <span className='flex nowrap gap-2'>
      <span>{(data?.temporary_out_of_stock == "0" && data?.stock_status === true) || data?.temporary_out_of_stock == null ? "Op voorraad" : "Niet op voorraad"}</span>
      <span className='block'>|</span>
    </span>
  </span>
  {data?.product_sku ? (
    <span className='flex gap-2 middle'>
      <span className='md-hide sku__pdp'>Artikel: {data?.product_sku}</span>
      <span className='hide md-block sku__pdp'>Artikelnummer: {data?.product_sku}</span>
    </span>
  ) : null}
</div>

      {reviewData?.review_count ? (
        <div className="flex gap-2 middle info">
          <Rating
            name="product-rating"
            value={Number(reviewData?.review_details?.averageRating ?? 0)}
            precision={0.1}
            readOnly
            emptyIcon={<Star fontSize="inherit" />}
          />
          <button className='fw-300' onClick={() => reviewHandle()} aria-label="button">{reviewData?.review_count} review(s)</button>
        </div>
      ) : (
        <div className="flex gap-2 middle info">
          <button className='fw-300' onClick={handleReviewClick} aria-label="button">Schrijf als eerste een review</button>
        </div>
      )}
    </div>
  )
}

export default ProductTitle