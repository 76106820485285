import "./styles.scss";
import ModelNew from 'Components/Model/ModelNew';
import CloseButton from 'Components/CloseButton';
import { Rating  , Dialog , IconButton} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";

const AllReviews = ({ openModel, setOpenModel, data }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const placeholderImage = useSelector(state => state?.getHeaderFooterData?.data?.footer?.[0]?.place_holder_image)  ||  localStorage.getItem("placeholderImage");

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };
    return (
        <>
        <ModelNew
            from="right"
            hideScroll={false}
            zindex={11}
            open={openModel}
            shadow={true}
            setOpen={setOpenModel}
            className="header__reviews__sidebar"
        >
            <div className="sidebar__reviews w-1/1 h-1/1 px-4 sm-px-6 py-4 overflow-hidden overflow-y-auto">
                <div className="close__block tr flex right w-1/1">
                    <CloseButton onClickFunction={() => setOpenModel(false)} />
                </div>
                <div className="reviews__wrapper__container">
                    <div className="sidebar__heading pb-5">
                        <h1 className="fw-700 mb-2 fs-20">Bekijk alle reviews</h1>
                    </div>
                    <div className="reviews__all">
                        {data &&
                            data?.map((item,index) => (
                                <div className="reviews__block flex col gap-3 py-5" key={`reviews_all_${index}`}>
                                    <div className="rating flex middle gap-3">
                                        <Rating
                                            name="half-rating-read"
                                            value={(item?.ratingValue/2)}
                                            precision={0.5}
                                            readOnly
                                        />
                                        <p className="fs-20 line-7 fw-700">{item?.ratingValue}</p>
                                    </div>
                                    <div className="info">
                                        <h3 className="fs-18 line-7 fw-700 pb-1">{item?.title}</h3>
                                        <p className="pb-2 fs-15 line-7">{item?.detail}</p>
                                        <div className="review_images_container">
                                        {item?.review_images?.map((img, index) => (
                                            <img
                                                key={index}
                                                className="Slider_review_images"
                                                src={img?.imageUrl}
                                                alt={`Slider_review_images${index}`}
                                                onClick={() => handleOpen(img?.imageUrl)}
                                                onError={(e) => (e.target.src = placeholderImage)}
                                                draggable={false}
                                                
                                            />
                                            ))}
                                        </div>        
                                        <p className=" pt-2 fs-15 line-7">
                                            {item?.created_at} | {item?.nickname}
                                        </p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </ModelNew>

        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" disableScrollLock>
        <div style={{ position: "relative", textAlign: "center"}}>
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseButton onClickFunction={()=>{setOpen(false)} }/>
          </IconButton>
          <img
            src={selectedImage}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "90vh" }}
            onError={(e) => (e.target.src = placeholderImage)}
          />
        </div>
      </Dialog>

      {/* <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" >
        <div style={{ position: "relative", textAlign: "center" , padding: 10px}}>
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseButton onClickFunction={()=>{setOpen(false)} }/>
          </IconButton>
          <img
            src={selectedImage}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "90vh" }}
            onError={(e) => (e.target.src = placeholderImage)}
          />
        </div>
      </Dialog> */}
        </>
    )
}

export default AllReviews;